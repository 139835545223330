import React from 'react'
import styled from 'styled-components'

import './global.css'
import { Link } from 'gatsby'

import vitalsigns from './vitalsigns.png'
import timeseries from './timeseries.png'
import tacx from './Tacx.jpg'
import xbike from './xbike.jpg'


var splendoBlue = '#2F76CA'

const OurProjects = styled.div`
  text-align: center;
  .sectionHeader {
    margin-bottom: 2vw;
  }
`

const Project = styled.div`
  position: relative;
  padding: 5vw 0;
  height: 900px;
  text-align: left;
  background-size: cover;
  background-position: center;
  margin-bottom: -10vw;
  @media (max-width: 800px) {
    height: 750px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:nth-child(odd) {
    clip-path: polygon(0% 0%, 0% 100%, 100% calc(100% - 5vw), 100% 0%);
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% calc(100% - 5vw), 100% 0%);
  }
  &:nth-child(even) {
    clip-path: polygon(0% 0%, 0%  calc(100% - 5vw), 100% 100%, 100% 0%);
    -webkit-clip-path: polygon(0% 0%, 0%  calc(100% - 5vw), 100% 100%, 100% 0%);
  }
  & > * {
    z-index: 5;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,0.7) 15%, rgba(0,0,0,0) 75%);
  }
  &.tacx {
    z-index: 10;
    background-image: url(${tacx});
  }
  &.timeseries {
    z-index: 9;
    background-image: url(${timeseries});
  }
  &.xbike {
    z-index: 8;
    background-image: url(${xbike});
  }
  &.vitalsigns {
    z-index: 1;
    background-image: url(${vitalsigns});
    background-position: top;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px;
  @media (max-width: 800px) {
    text-align: center;
  }
  h1 {
    font-size: 2.5em;
    width: 60%;
    line-height: 1.2em;
    font-family: nunitoExtraBold, sans-serif;
    @media (max-width: 800px) {
      font-size: 2.3em;
      width: 100%;
    }
  }
  span {
    display: block;
    max-width: 400px;
    margin: 30px 0 0;
    font-family: nunitoSemi, sans-serif;
    @media (max-width: 800px) {
      width: 90%;
      margin: 30px auto 0;
    }
  }
  &.alignRight {
    right: 0;
    padding: 0;
    width: 40%;
    max-width: 500px;
    @media (max-width: 800px) {
      width: 100%;
    }
    h1 {
      width: 100%;
    }
  }
  a {
    display: inline-block;
    padding: 16px 30px;
    margin: 40px 0;
    color: white;
    border-radius: 10px;
    background-color: ${splendoBlue};
    text-transform: uppercase;
    transition: transform 300ms ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
`

function Projects() {
  return (
    
    <OurProjects>
      <div className="projects">
        <Project className="tacx" id="tacx">
          <Wrapper>
            <h1>Tacx / Garmin</h1>
            <span>
              Splendo built the initital mobile training app and cloud platform in close collaboration with Tacx, world leader in the development of in-home cycling trainers. GPS or video based routes automatically adjust the trainer's resistance, mimicking the road conditions on screen. Video playback speed is adjusted to the athlete's effort, to further enhance the experience. After acquisition by Garmin, high quality code and documentation ensured a seamless handover to their in-house team for further development.
            </span>
            <div>
              <Link to="/products/tacx">View Details</Link>
            </div>
          </Wrapper>
        </Project>
        <Project className="timeseries">
          <Wrapper>
            <h1>Time Series Deep Learning Model</h1>
            <span>
              Splendo develops generic deep learning models for timeseries data. Many data sources are never 'complete' because new data points are
              continuously added. Splendo is able to convert such data into a so-called 'time series stream', after which predictions can be made with
              this data using deep learning models.
            </span>
            <div>
              <Link to="/products/time">View Details</Link>
            </div>
          </Wrapper>
        </Project>

        <Project className="xbike" id="xbike">
          <Wrapper>
            <h1>X.bike: flexible smart bike platform</h1>
            <span>
              Splendo built the native Android and iOS apps and cloud for X.bike's modular white-label smart bike platform. It allows clients to set up and run any type of bike share or rental operation with  the industry leading AxA E-RL smart locks.
            </span>
            <div>
              <Link to="/projects/xbike">View Details</Link>
            </div>
          </Wrapper>
        </Project>

        <Project className="vitalsigns">
          <Wrapper>
            <h1>Vital Signs Smart Shirt</h1>
            <span>
              Splendo NANO TECH shirt is a result of Splendo's participation in the Qualcomm Tricorder XPRIZE. This is a smart wearable consisting of
              a shirt and software to monitor and measure vital signs using non-invasive technologies.
            </span>
            <div>
              <Link to="/products/vital">View Details</Link>
            </div>
          </Wrapper>
        </Project>
      </div>
    </OurProjects>
  )
}

export default Projects
