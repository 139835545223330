import {asyncComponent} from "react-async-component";
import React, {Children} from "react";
import bg1 from './heroBg3.jpg'

const SSRComponent = () => <img src={bg1} alt="Splendo" />

const SSRFadethrough = asyncComponent({
    resolve: () => typeof window === 'undefined' ? Promise.resolve(SSRComponent) : import('react-fadethrough'),
    LoadingComponent: SSRComponent
})

export default SSRFadethrough